// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--LZE0a";
export var container = "Footer-module--container--cIk5r";
export var row = "Footer-module--row--u7ak7";
export var logoWrapper = "Footer-module--logo-wrapper--Vdfya";
export var logo = "Footer-module--logo--Lu+fA";
export var socialLinks = "Footer-module--social-links--Elasn";
export var socialLink = "Footer-module--social-link--ZfpD1";
export var focusVisible = "Footer-module--focus-visible--VgjNF";
export var backToTopWrapper = "Footer-module--back-to-top-wrapper--BPC36";
export var backToTop = "Footer-module--back-to-top--hSmAT";
export var smOnly = "Footer-module--sm-only--6cT3q";
export var mdOnly = "Footer-module--md-only--YAxKp";
export var lgOnly = "Footer-module--lg-only--9+neH";
export var xlOnly = "Footer-module--xl-only--y5hfx";
export var xxlOnly = "Footer-module--xxl-only--Synfv";
export var backToTopButtonArrow = "Footer-module--back-to-top-button-arrow--YrCzy";
export var backToTopButtonText = "Footer-module--back-to-top-button-text--doDeP";
export var backToTopButton = "Footer-module--back-to-top-button--BtSvR";
export var copyrightRow = "Footer-module--copyright-row--7XC7b";
export var copyrightCol = "Footer-module--copyright-col--AGGza";
export var copyright = "Footer-module--copyright--p3W1o";
export var slideUp = "Footer-module--slideUp--7bZOn";