import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import sal, { Options } from "sal.js";

import { useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Footer, { scrollToTop } from "../Footer/Footer";
import * as styles from "./Layout.module.scss";
import Button from "../Button/Button";

interface QueryTypes {
  site: {
    siteMetadata: {
      title: string;
    };
  };
}

interface LayoutProps {
  children: React.ReactNode;
  floatingBackToTopButton?: boolean;
  hideBackToTop?: boolean;
  hideContact?: boolean;
  hideTitle?: boolean;
}

function Layout({
  children,
  floatingBackToTopButton,
  hideBackToTop,
  hideContact,
  hideTitle,
}: LayoutProps): JSX.Element {
  const [scrollTop, setScrollTop] = useState(0);
  const backToTopButtonClassName = useRef(new Set([styles.backToTopButton]));

  useEffect(() => {
    sal({
      threshold: 0.01,
      selector: "[data-breeze]",
      animateClassName: "breeze-in",
    } as Options);
  }, []);

  useEffect(() => {
    const onScroll = (event: Event): void => {
      setScrollTop((event.target as Document).documentElement.scrollTop);
    };
    global.window.addEventListener("scroll", onScroll);
    setScrollTop(document.documentElement.scrollTop);
    return () => {
      global.window.removeEventListener("scroll", onScroll);
    };
  }, [setScrollTop]);

  const data = useStaticQuery<QueryTypes>(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  function showBackToTopButton(): void {
    if (!backToTopButtonClassName.current.has(styles.backToTopButtonVisible)) {
      backToTopButtonClassName.current.add(styles.backToTopButtonVisible);
    }
  }

  function hideBackToTopButton(): void {
    if (backToTopButtonClassName.current.has(styles.backToTopButtonVisible)) {
      backToTopButtonClassName.current.delete(styles.backToTopButtonVisible);
    }
  }

  if (global.window && scrollTop > global.window.innerHeight * 0.8) {
    showBackToTopButton();
  } else {
    hideBackToTopButton();
  }

  return (
    <>
      <Header
        hideContact={hideContact}
        hideTitle={hideTitle}
        siteTitle={data.site.siteMetadata.title}
      />
      <main>
        {children}
        {floatingBackToTopButton && (
          <Button
            className={Array.from(backToTopButtonClassName.current).join(" ")}
            onClick={scrollToTop}
            type="button"
            variant="link"
          >
            <span className={styles.backToTopButtonArrow}>&uarr;</span>
            <span className={styles.backToTopButtonText}>back to top</span>
          </Button>
        )}
      </main>
      <Footer
        hideBackToTop={hideBackToTop}
        hideBackToTopOnBreakpoint={floatingBackToTopButton ? "xl" : undefined}
        siteTitle={data.site.siteMetadata.title}
      />
    </>
  );
}

Layout.defaultProps = {
  floatingBackToTopButton: false,
  hideBackToTop: false,
  hideContact: false,
  hideTitle: false,
};

export default Layout;
