import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

export type Meta = Array<
  | {
      content: string;
      name: string;
    }
  | {
      content: string;
      property: string;
    }
>;

interface HeadProps {
  description?: string;
  lang?: string;
  meta?: Meta;
  title: string;
  useTitleTemplate?: boolean;
}

interface QueryTypes {
  site: {
    siteMetadata: {
      author: string;
      description: string;
      twitter: string;
    };
  };
}

function Head({
  description,
  lang,
  meta: propsMeta,
  title,
  useTitleTemplate = true,
}: HeadProps): JSX.Element {
  const { site } = useStaticQuery<QueryTypes>(
    graphql`
      query {
        site {
          siteMetadata {
            author
            description
            twitter
          }
        }
      }
    `,
  );

  const metaDescription =
    description ||
    `${site.siteMetadata.author} | ${site.siteMetadata.description}`;

  let meta = [
    {
      name: "description",
      content: metaDescription,
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:description",
      content: metaDescription,
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      name: "twitter:card",
      content: "summary",
    },
    {
      name: "twitter:site",
      content: site.siteMetadata.twitter,
    },
    {
      name: "twitter:title",
      content: title,
    },
    {
      name: "twitter:description",
      content: metaDescription,
    },
  ];

  if (propsMeta) {
    meta = meta.concat(propsMeta);
  }

  return (
    <Helmet
      defer={false}
      htmlAttributes={{
        lang,
      }}
      meta={meta}
      title={title}
      titleTemplate={
        useTitleTemplate ? `%s | ${site.siteMetadata.author}` : undefined
      }
    />
  );
}

Head.defaultProps = {
  description: "",
  lang: "en",
  meta: [],
  useTitleTemplate: true,
};

export default Head;
