import * as React from "react";
import { useTheme } from "../../hooks/theme";
import Switch from "./Switch";

function DarkModeSwitch({ id }: { id?: string }): JSX.Element {
  const [theme, toggleTheme] = useTheme();
  return (
    <Switch
      checked={theme === "dark"}
      id={id}
      label="Dark mode"
      onChange={(event): void => {
        toggleTheme(event.target.checked ? "dark" : "light");
      }}
    />
  );
}

DarkModeSwitch.defaultProps = {
  id: undefined,
};

export default DarkModeSwitch;
