import * as React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import Button from "../Button/Button";
import DarkModeSwitch from "../Switch/DarkModeSwitch";
import Logo from "../Logo/Logo.svg";
import Sun from "./sun.svg";
import Moon from "./moon.svg";

import * as styles from "./Header.module.scss";

interface HeaderProps {
  hideContact?: boolean;
  hideTitle?: boolean;
  siteTitle: string;
}

function Header({
  hideContact,
  hideTitle,
  siteTitle,
}: HeaderProps): JSX.Element {
  const location = useLocation();
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.row}>
          {!hideTitle && (
            <div className={styles.titleWrapper}>
              <Link className={styles.titleLink} to="/">
                <Logo className={styles.logo} />
                <h1 className={styles.title}>{siteTitle}</h1>
              </Link>
            </div>
          )}
          <div className={styles.buttons}>
            {location.pathname === "/" ? (
              <Button
                className={styles.button}
                onClick={(): void => {
                  document.getElementById("about-me")?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
                type="button"
                variant="outline-primary"
              >
                about me
              </Button>
            ) : (
              <Button
                className={styles.button}
                to="/#about-me"
                variant="outline-primary"
              >
                about me
              </Button>
            )}
            {!hideContact && (
              <Button
                className={styles.button}
                to="/contact/"
                variant="primary"
              >
                get in touch
              </Button>
            )}
            <div className={styles.themeSwitcher}>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className={styles.toggleLabel} htmlFor="theme-switcher">
                <Sun />
              </label>
              <DarkModeSwitch id="theme-switcher" />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className={styles.toggleLabel} htmlFor="theme-switcher">
                <Moon />
              </label>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

Header.defaultProps = {
  hideContact: false,
  hideTitle: false,
};

export default Header;
