import * as React from "react";
import Button from "../Button/Button";
import Logo from "../Logo/Logo.svg";
import Instagram from "./instagram.svg";
import LinkedIn from "./linked-in.svg";

import * as styles from "./Footer.module.scss";

interface FooterProps {
  hideBackToTop?: boolean;
  hideBackToTopOnBreakpoint?: "lg" | "md" | "sm" | "xl" | "xxl";
  siteTitle: string;
}

export function scrollToTop(): void {
  const prefersReducedMotion = window.matchMedia(
    "(prefers-reduced-motion)",
  ).matches;
  window.scrollTo({
    top: 0,
    behavior: !prefersReducedMotion ? "smooth" : "auto",
  });
}

function Footer({
  siteTitle,
  hideBackToTop,
  hideBackToTopOnBreakpoint,
}: FooterProps): JSX.Element {
  const backToTopClassName = new Set([styles.backToTop]);
  switch (hideBackToTopOnBreakpoint) {
    case "sm":
      backToTopClassName.add(styles.smOnly);
      break;
    case "md":
      backToTopClassName.add(styles.mdOnly);
      break;
    case "lg":
      backToTopClassName.add(styles.lgOnly);
      break;
    case "xl":
      backToTopClassName.add(styles.xlOnly);
      break;
    case "xxl":
      backToTopClassName.add(styles.xxlOnly);
      break;
    default:
      break;
  }
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.logoWrapper} data-breeze="slide-up">
            <Logo className={styles.logo} />
          </div>
          <div className={styles.socialLinks} data-breeze="slide-up">
            <h3>social links</h3>
            <div>
              <a
                className={styles.socialLink}
                href="https://www.linkedin.com/in/justineseverino/"
                title="LinkedIn"
              >
                <LinkedIn />
              </a>
              <a
                className={styles.socialLink}
                href="https://www.instagram.com/creativeju.s/"
                title="Instagram"
              >
                <Instagram />
              </a>
            </div>
          </div>
          <div className={styles.backToTopWrapper}>
            {!hideBackToTop && (
              <div
                className={Array.from(backToTopClassName).join(" ")}
                data-breeze="slide-up"
              >
                <Button
                  className={styles.backToTopButton}
                  onClick={scrollToTop}
                  type="button"
                  variant="link"
                >
                  <span className={styles.backToTopButtonArrow}>&uarr;</span>
                  <span className={styles.backToTopButtonText}>
                    back to top
                  </span>
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className={styles.copyrightRow}>
          <div className={styles.copyrightCol}>
            {/* Using regular animation because the copyright notice is lower than the intersection observer threshold */}
            <p className={styles.copyright}>
              © {siteTitle} | {new Date().getFullYear()}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.defaultProps = {
  hideBackToTop: false,
  hideBackToTopOnBreakpoint: undefined,
};

export default Footer;
