import * as React from "react";
import { ChangeEventHandler } from "react";

import * as styles from "./Switch.module.scss";

interface SwitchProps {
  checked?: boolean;
  id?: string;
  label?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

function Switch(props: SwitchProps): JSX.Element {
  const { checked, id, label, onChange } = props;
  return (
    <input
      aria-label={label}
      checked={checked}
      className={styles.toggle}
      id={id}
      onChange={onChange}
      type="checkbox"
    />
  );
}

Switch.defaultProps = {
  checked: false,
  id: undefined,
  label: undefined,
  onChange: undefined,
};

export default Switch;
