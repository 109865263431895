import { useCallback, useEffect, useState } from "react";

type Theme = "dark" | "light";

declare global {
  interface Window {
    __setPreferredTheme: (theme: Theme) => void;
    __theme: Theme;
    __themeEventTarget: EventTarget;
  }
}

/* eslint-disable no-underscore-dangle */
export const useTheme = (): [typeof currentTheme, typeof toggleTheme] => {
  const [currentTheme, setCurrentTheme] = useState<Theme>(
    typeof window === "undefined" ? "light" : window.__theme,
  );

  const callback = (): void => {
    setCurrentTheme(window.__theme);
  };

  useEffect(() => {
    window.__themeEventTarget.addEventListener("change", callback);
    return () => {
      window.__themeEventTarget.removeEventListener("change", callback);
    };
  }, []);

  const toggleTheme = useCallback((theme: Theme) => {
    window.__setPreferredTheme(theme);
  }, []);

  return [currentTheme, toggleTheme];
};
/* eslint-enable no-underscore-dangle */
